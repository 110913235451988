import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import anim from '../../../images/projekty/betard/2/mocny_partner.mp4'
import wideo from '../../../images/projekty/betard/2/okladka.mp4'
import KolImg from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Info from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_foto'
import SliderTaby from '../comp/slider_taby'
import TytulPunkty from '../comp/tytul_punkty'




export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/betard/2/slider/odpowiedzialnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/betard/2/slider/rozwoj.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/betard/2/slider/niezawodnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/2/slider/efektywnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                kompozycja: file(relativePath: { eq: "projekty/betard/2/kompozycja@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/2/brandbook1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/2/brandbook2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                tab1: file(relativePath: { eq: "projekty/betard/2/taby/segmentacja@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                tab2: file(relativePath: { eq: "projekty/betard/2/taby/targetowanie@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                tab3: file(relativePath: { eq: "projekty/betard/2/taby/pozycjonowanie@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    
    
    return(
        <>

        <Rozdzial tytul="STRATEGIA MARKI" invert={true} tekst="Rozpoczynając współpracę z firmą Betard mieliśmy świadomość potrzeby wprowadzenia korekt w wizerunku marki, ale ze względu na ogromny kapitał wizerunkowy musiały one być prowadzone w sposób zachowawczy i ustrukturyzowany. Niezbędnym elementem działań było opisanie istniejącej marki i dookreślenie elementów, które pozwalają dziś w sposób metodyczny i spójny kontrolować jej wizerunek na wielu, zróżnicowanych polach ekspozycji." nr="02." wideo={wideo}>


        <Info invert={true} tytul="Fundamenty działań" tekst="Oparametryzowanie funkcjonowania firmy i poznanie grup klientów to klucz do opracowania efektywnej strategii marki. Dzięki jasnym celom, budowaniu marki w ramach zamierzonego modelu biznesowego, ze świadomością potrzeb, które może zaspokajać i problemów, które może rozwiązywać, komunikacja staje się merytoryczna, skuteczna i angażująca a marka jest tym czego klienci oczekują." />
        <TytulPunkty tekst="Naszym zadaniem było odświeżenie wizerunku marki i synergia jej obecności w zróżnicowanych kanałach komunikacji" />  
        <Info invert={false} tytul="Proces tworzenia wizerunku" tekst="Trzyetapowy proces pozwolił nam opisać kryteria podziału rynku materiałów budowlanych i prefabrykacji, określić podział ról w procesie decyzyjnym, wyodrębnić grupy klientów i użytkowników produktów marki oraz przygotować fundamenty komunikacyjne, które odpowiadają na pragnienia i problemy indywidualnych grup odbiorców." />

        <Slider tytul="Wartości marki Betard" foto={foto} />

        <div><Img fluid={data.vp.childImageSharp.fluid} /></div>

        <SliderTaby img1={data.tab1.childImageSharp.fluid} img2={data.tab2.childImageSharp.fluid} img3={data.tab3.childImageSharp.fluid}
        tytul1="SEGMENTACJA"
        tekst1="To pierwszy element strategii marki, który stanowi fundament dla dalszych działań koncepcyjnych i późniejszych decyzji komunikacyjnych. Na etapie segmentacji określiliśmy definicję branży, wyróżniliśmy jej głównych graczy i kryteria podziału kategorii produktowych, które później odnieśliśmy do profili grup klientów na etapie targetowania."
        tytul2="TARGETOWANIE"
        tekst2="Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity."
        tytul3="POZYCJONOWANIE"
        tekst3="Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego Pseudo-Areopagity." />

        <KolImg tytul="Esencja marki" tekst="Hasło marki zostało rozwinięte tak, by móc skutecznie komunikować się z kluczowymi grupami interesariuszy firmy Betard." wideo={anim} />
        
        
        <TytulPunkty tekst="Po opisaniu najważniejszych elementów marki, kolejnym etapem działań było uporządkowanie aspektów wizualnych i zaprojektowania świata Betard, tak by oddawał jego osobowość, wartości i rezonował z kluczowymi grupami odbiorców firmy." />
        <div><Img fluid={data.kompozycja.childImageSharp.fluid} /></div>

        <Komp_kol tekst1="Instrukcja użycia marki została opisana w formie brandbooka. Dzięki niemu pracownicy, partnerzy, czy agencje obsługujące mogą przyspieszyć proces działań i upewnić się, że marka rozwija się w jednym, ustalonym kierunku, wysyłając oczekiwane i spójne komunikaty wobec swoich interesariuszy." tytul1="Brandbook" img1={data.komp1.childImageSharp.fluid}  img2={data.komp2.childImageSharp.fluid} />

        

        
        
        </Rozdzial>
        
        </>
    )
}