import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import anim1 from '../../../images/projekty/betard/7/anim1.mp4'
import anim2 from '../../../images/projekty/betard/7/anim2.mp4'
import anim3 from '../../../images/projekty/betard/7/anim3.mp4'
import wideo from '../../../images/projekty/betard/7/okladka.mp4'
import { default as KolImg, default as KolTekst } from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'



export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/betard/7/okladka@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }

                prezentacje: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                    prezentacje2: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }

                    prezentacja: file(relativePath: { eq: "projekty/betard/7/prezentacja@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }


                    mockup: file(relativePath: { eq: "projekty/betard/7/mockup@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }    

                        mockup2: file(relativePath: { eq: "projekty/betard/7/mockup2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            }  


                            mockup3: file(relativePath: { eq: "projekty/betard/7/mockup3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                                childImageSharp {
                                    fluid(maxWidth: 2000, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                                } 


            komp1: file(relativePath: { eq: "projekty/betard/7/komp1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }  
                
                komp2: file(relativePath: { eq: "projekty/betard/7/komp2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }  
}
            `)

    


   
    
    return(
        <>

        <Rozdzial tytul="DIGITAL DESIGN" invert={false} tekst="Obecność cyfrowa firmy Betard składa się m.in. z tworzenia materiałów dedykowanych własnej stronie interetowej, komunikacji w mediach społecznościowych, prezentacji wyświetlanych podczas wydarzeń branżowych i spotkań z klientami, czy treści wykorzystywanych do działań partnerskich (m.in. branżowych i sportowych). Poznaj przykłady realizacji tworzonych przez nas dla firmy Betard." nr="07." wideo={wideo}>
        
        <KolTekst tytul="Prezentacje cyfrowe" tekst="Przykład prezentacji cyfrowej marki, wyświetlanej podczas jednego z kluczowych wydarzeń branżowych organizowanych we Wrocławiu - Dni Prefabrykacji. " img={data.prezentacje.childImageSharp.fluid} end={true}/>

        <div><Img fluid={data.prezentacja.childImageSharp.fluid}></Img></div>

        <KolTekst tytul="Layout" tekst="Layout prezentacji to ewolucja przyjętego systemu publikacji, zbieżnego z założeniami katalogów produktowych, informatora dla pracowników i wszystkich innych materiałów marki Betard." img={data.prezentacje2.childImageSharp.fluid} invert={true} end={true}/>

        <div><Img fluid={data.mockup.childImageSharp.fluid}></Img></div>
   
        <TytulPunkty tekst="Wewnętrzne mailingi skierowane do pracowników, wspierające implementację innowacji, informowanie o aktualnościach i angażowanie w działania dodatkowe organizowane przez firmę." />

        <div><Img fluid={data.mockup2.childImageSharp.fluid}></Img></div>

        <TytulPunkty tekst="W ramach kompleksowej obsługi tworzymy wszystkie niezbędne materiały, które wymusza obecność  firmy w przestrzeni cyfrowej."  />

        <KolImg tytul="                               " tekst="" wideo={anim3} />

        <div><Img fluid={data.mockup3.childImageSharp.fluid}></Img></div>


        <Komp_kol invert={true}  wideo1={anim1} wideo2={anim2} />


        <TytulPunkty tekst="Tworzymy rozwiązania cyfrowe w kontekście bieżącej działalności, eventów sportowych czy działań edukacyjnych firmy Betard." />

        </Rozdzial>

        
        
        </>
    )
}