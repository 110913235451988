import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import KolTekst from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_foto'
import TytulPunkty from '../comp/tytul_punkty'





export default function Ilustracja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/betard/4/slider/1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/betard/4/slider/2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/betard/4/slider/3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/4/slider/4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/betard/4/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja1: file(relativePath: { eq: "projekty/betard/4/ilustracja1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja2: file(relativePath: { eq: "projekty/betard/4/ilustracja2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/4/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/4/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full: file(relativePath: { eq: "projekty/betard/4/full@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    
    
    return(
        <>

        <Rozdzial tytul="ILUSTRACJA" invert={true} tekst="Techniczność i skomplikowanie działań firmy Betard, wymaga przemyślanej komunikacji i produkcji elementów wizualnych. Organicznym elementem graficznym branży jest rysunek techniczny, który zastosowany został w ramach systemu wizualnego. Wprowadzanie prześwietleń pozwoliło nam na wizualizację elementów prefabrykowanych w zamkniętych bryłach budynków. Naszym zadaniem było także stworzenie kompletu ilustracji produktowych i zwizualizowanie ich zastosowania w realizacjach. Całość uzupełniona została elementami graficznymi wywodzącymi się z systemu graficznego marki." nr="04." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Naszym podstawowym założeniem było utrzymanie technicznego charakteru marki, w wyróżniającej się i zindywidualizowanej formie." tekst1="Elementy graficzne stworzone zostały do szerokiej palety zastosowań - od profili produktów na stronie marki, przez katalogi, do reklam prasowych i kart produktów" tekst2="Ważnym elementem było stopniowanie elementów i możliwość ich łączenia w zależności od oczekiwanego kontekstu."/>

        
    
      

        <div><Img fluid={data.ilustracja1.childImageSharp.fluid}></Img></div>

        <Slider tytul="Rodzaje ilustracji" foto={foto} />

        
        <KolTekst tytul="Skala" tekst="W ramach zadania stworzyliśmy setki ilustracji produktowych, dziesiątki ilustracji realizacji oraz setki kompozycji zawierających prześwietlenia i informacje techniczne." img={data.produkty.childImageSharp.fluid} end={true} />

        <div><Img fluid={data.produkty_animacja.childImageSharp.fluid}></Img></div>

        <Komp_kol invert={true} img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} />

        <TytulPunkty tekst="Zastosowane rozwiązania sprawiają, że materiały marki są spójne, czytelne i charakterystyczne." />

        <div><Img fluid={data.ilustracja2.childImageSharp.fluid}></Img></div>

        <KolTekst tytul="Prześwietlenia" tekst="Prześwietlenia nanoszone na gotowe realizacje pozwalają nam wskazać elementy realizowane przez markę nawet gdy są niewidoczne na zdjęciach a ich kompozycja z bielą i czernią  pomaga uwypuklić istotę komunikatu." img={data.przeswietlenia.childImageSharp.fluid} invert={true} end={false} />

        <div><Img fluid={data.full.childImageSharp.fluid}></Img></div>
        
        
        </Rozdzial>
        
        </>
    )
}