import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Research from '../../components/projekty/betard/1'
import Strategia from '../../components/projekty/betard/2'
import Identyfikacja from '../../components/projekty/betard/3'
import Ilustracja from '../../components/projekty/betard/4'
import Print from '../../components/projekty/betard/5'
import WebDesign from '../../components/projekty/betard/6'
import Digital from '../../components/projekty/betard/7'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Ocena from '../../components/projekty/OcenaProjektu'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import paluch from '../../images/ikony/paluch.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import okladka2 from '../../images/projekty/betard/okladka2@2x.jpg'
import okladka from '../../images/projekty/betard/okladka@2x.jpg'
import style from './projekt.module.css'





let title = "BETARD"

export default function Betard() {
    // polyfill();
    const slider = useRef(null)
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)
    const r5 = useRef(null)
    const r6 = useRef(null)
    const r7 = useRef(null)
    const r8 = useRef(null)

    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
    )

        


    




        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }    



    return (    
        <>
        <div style={{
            // height: "calc(100vh - 60px)",
            // overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2018-2021</span>
                        <div className="projekt-zajawka-kategorie">
                            <span className="przycisk">Badania i koncept</span>
                            <span className="przycisk">strategia wizualna</span>
                            <span className="przycisk">brand design</span>
                            <span className="przycisk">katalogi</span>
                            <span className="przycisk">akcydensy</span>
                            <span className="przycisk">web design</span>
                        </div>
                        
                        <p>Betard to wiodący producent prefabrykatów betonowych na rynku Polskim. Od 2019 roku odpowiadamy za działania brandingowe marki.</p>
                        <span 
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                        className="przycisk"><img src={paluch} style={{marginRight: '10px'}}/>ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. RESEARCH I KONCEPCJA</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. STRATEGIA MARKI</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. SYSTEM IDENTYFIKACJI MARKI</span>  
                        <span onClick={
                            () => { r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(3);}}
                        className="przycisk">04. ILUSTRACJA</span>  
                        <span onClick={
                            () => { r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(4);}}
                        className="przycisk">05. PRINT DESIGN</span>  
                        <span onClick={
                            () => { r6.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(5);}}
                        
                        className="przycisk">06. WEB DESIGN</span>  
                        <span onClick={
                            () => { r7.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(6);}} className="przycisk">07. DIGITAL DESIGN</span>  
                         
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="Betard to wiodący producent prefabrykatów betonowych na rynku Polskim. Firma zatrudnia przeszło 1000 pracowników, podzielonych pomiędzy 6 zakładami produkcyjnymi w całym kraju, obsługując wiodące inwestycje przemysłowe, mieszkaniowe czy infrastrukturalne. Od 2019 roku odpowiadamy za działania brandingowe marki." image={okladka} />
            <div className="podstrona-wrapper projekt-padding">

            <img style={{width: '100%'}} src={okladka} />

            <Intro tytul="Wprowadzenie do projektu:" tekst="Betard to wiodący producent prefabrykatów betonowych na rynku Polskim. Firma zatrudnia przeszło 1000 pracowników, podzielonych pomiędzy 6 zakładami produkcyjnymi w całym kraju, obsługując wiodące inwestycje przemysłowe, mieszkaniowe czy infrastrukturalne. Od 2019 roku odpowiadamy za działania brandingowe marki." tekst1="Drugie miejsce na liście największych dolnośląskich firm według rankingu Diamenty Forbes 2020." tekst2="Najsilniejszy medialnie producent materiałów budowlanych w Polsce, według rankingu Top Marka 2020 magazynu branżowego PRESS" />

            <img style={{width: '100%'}} src={okladka2} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01. RESEARCH I KONCEPCJA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. STRATEGIA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. SYSTEM IDENTYFIKACJI MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                        <div onClick={() => r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={4}>
                            <span className="przycisk">
                                04. ILUSTRACJA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={5}>
                            <span className="przycisk">
                                05. PRINT DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r6.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={6}>
                            <span className="przycisk">
                                06. WEB DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>


                        <div onClick={() => r7.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={7}>
                            <span className="przycisk">
                                07. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        


           
                </div>
            </div>
            </div>


            <InView  as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('0')}>
            <div ref={r1}><Research  /></div>
            </InView>

            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('1')}>
            <div ref={r2}><Strategia /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('2')}>
            <div ref={r3}><Identyfikacja  /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('3')}>
            <div ref={r4}><Ilustracja /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('4')}>
            <div ref={r5}><Print /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('5')}>
            <div ref={r6}><WebDesign /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('6')}>
            <div ref={r7}><Digital /></div>
            </InView>
         

            <div className="podstrona-wrapper">
            <Ocena tekst='"Postawiliśmy przed zespołem Odry duże wyzwanie i czujemy, że dostarczane rozwiązania są niezwykle świadome, jakościowe i nowoczesne. Proces redesignu marki przebiega bardzo sprawnie i kompleksowo a jego efektem jest odświeżenie i jeszcze większa profesjonalizacja wizerunku marki. Zaprojektowana strona internetowa jest zgodna z najnowszymi trendami, zarówno w obszarze technologicznym jak i w kontekście rozwiązań user experience oraz user inter- face. W ramach bieżącej współpracy czujemy wsparcie oraz ogromne zaangażowanie Odry, co jest dla nas niezwykle istotne."' firma="Brand Manager, Betard" imie="Karolina Dziechcińska" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Betard" />


            


            <Formularz />
            </div>
      
         
        </> 
    )
}