import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import KolTekst from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import PunktSrodek from '../comp/punkt_srodek'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'





export default function Print() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/betard/5/slider/1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/betard/5/slider/2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }


                slider_3: file(relativePath: { eq: "projekty/betard/5/slider/3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/5/slider/4@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_5: file(relativePath: { eq: "projekty/betard/5/slider2/1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_6: file(relativePath: { eq: "projekty/betard/5/slider2/2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_7: file(relativePath: { eq: "projekty/betard/5/slider2/3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_8: file(relativePath: { eq: "projekty/betard/5/slider3/1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_9: file(relativePath: { eq: "projekty/betard/5/slider3/2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_10: file(relativePath: { eq: "projekty/betard/5/slider3/3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_d1: file(relativePath: { eq: "projekty/betard/5/slider_d1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }
    
                slider_d2: file(relativePath: { eq: "projekty/betard/5/slider_d2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/betard/4/okladka@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta1: file(relativePath: { eq: "projekty/betard/5/oferta@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                oferta2: file(relativePath: { eq: "projekty/betard/5/oferta2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta3: file(relativePath: { eq: "projekty/betard/5/oferta3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta4: file(relativePath: { eq: "projekty/betard/5/oferta4@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta5: file(relativePath: { eq: "projekty/betard/5/oferta5@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta6: file(relativePath: { eq: "projekty/betard/5/oferta6@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta7: file(relativePath: { eq: "projekty/betard/5/oferta7@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta8: file(relativePath: { eq: "projekty/betard/5/oferta8@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/5/komp1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/5/komp2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/betard/5/komp3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/betard/5/komp4@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp5: file(relativePath: { eq: "projekty/betard/5/komp5@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp6: file(relativePath: { eq: "projekty/betard/5/komp6@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp7: file(relativePath: { eq: "projekty/betard/5/komp7@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp8: file(relativePath: { eq: "projekty/betard/5/komp8@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp9: file(relativePath: { eq: "projekty/betard/5/komp9@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp10: file(relativePath: { eq: "projekty/betard/5/komp10@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp11: file(relativePath: { eq: "projekty/betard/5/komp11@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp12: file(relativePath: { eq: "projekty/betard/5/komp12@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp13: file(relativePath: { eq: "projekty/betard/5/komp13@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp14: file(relativePath: { eq: "projekty/betard/5/komp14@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                cementowoz: file(relativePath: { eq: "projekty/betard/5/cementowoz@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                kol_tekst1: file(relativePath: { eq: "projekty/betard/5/kol_tekst1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }



            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]

    const foto2 = [data.slider_5.childImageSharp.fluid, data.slider_6.childImageSharp.fluid, data.slider_7.childImageSharp.fluid]

    const slider_d = [data.slider_d1.childImageSharp.fluid, data.slider_d2.childImageSharp.fluid]

    const slider_d2 = [data.slider_8.childImageSharp.fluid, data.slider_9.childImageSharp.fluid, data.slider_10.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="PRINT DESIGN" invert={false} tekst="Materiały akcydensowe marki Betard występują w postaci klasycznych druków biznesowych, gadżetów dla partnerów i materiałów technicznych takich jak pojazdy flotowe (maszyny i osobowe), elementy wayfindingu, materiały produktowe, reklamy i druki wewnętrzne. Publikacje marki przedstawione są w oddzielnej sekcji." nr="05." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Skala działalności firmy sprawia, że rocznie produkujemy tysiące materiałów na niezwykle zróżnicowane pola ekspozycji." />

        
    
      

        <div><Img fluid={data.oferta1.childImageSharp.fluid}></Img></div>
        <Komp_kol invert={true} img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} tytul2="Publikacje ofertowe" tekst2="System publikacji firmowych został precyzyjnie zaplanowany i wdrożony, tak by w niedalekiej przyszłości zostać całościowo zintegrowanym ze sposobem prezentacji oferty marki, zarówno offline jak i online (m.in. w ramach firmowej strony www)." />

        <TytulPunkty tekst="Publikacje ofertowe Betard to sześć katalogów ofertowych, cenniki i portfolio realizacji. Łącznie ponad 1000 stron informacji technicznych." />

        <div><Img fluid={data.oferta2.childImageSharp.fluid}></Img></div>

        <TytulPunkty tekst="Wszystkie publikacje tworzone są w oparciu o zaplanowany system, by zachować spójność i oddać charakter marki." />

         
        <Komp_kol invert={true} img2={data.komp3.childImageSharp.fluid} img1={data.komp4.childImageSharp.fluid} tytul2="Proces projektowania 
publikacji" tekst2="Proces projektowania rozpoczął etap analizy potrzeb firmy, klientów i analiza dotychczasowych materiałów. Kolejnym krokiem było stworzenie tzw. master’a, dzięki któremu utrzymaliśmy jednorodny charakter w przekroju wszystkich publikacji. Naszym zadaniem było także stworzenie rysunków technicznych, przygotowanie fotografii i uzupełnianie treści. Aby osiągnąć oczekiwany efekt dobraliśmy odpowiednie surowce, uszlachetnienia i przygotowaliśmy pliki produkcyjne by ostatecznie współpracować z drukarnią i dostarczyć materiały do klienta." />

<TytulPunkty tekst="Ze względu na skomplikowaną technologię, indywidualizacje i sposób sprzedaży produktów, katalogi produktowe to kluczowy element sprzedażowy firmy." />

       

       
        

       

        

       

       
        
        <div><Img fluid={data.oferta3.childImageSharp.fluid}></Img></div>

        <TytulPunkty tekst="Wszystkie publikacje tworzone są w oparciu o zaplanowany system, by zachować spójność i oddać charakter marki." />

        <KolTekst tytul="Publikacja dla nowych pracowników" tekst="W związku z dokonanym przez firmę Betard przejęciem konkurencyjnego przedsiębiorstwa, elementem naszych działań była prezentacja marki przed nowymi pracownikami. Publikacja określa wartości, prezentuje zakres produktów, wskazuje działania strategiczne i buduje poczucie wspólnej tożsamości w ramach przedsiębiorstwa." img={data.kol_tekst1.childImageSharp.fluid} end={true} />

        <div><Img fluid={data.oferta8.childImageSharp.fluid}></Img></div>

        <Komp_kol invert={true}  img2={data.komp13.childImageSharp.fluid} img1={data.komp14.childImageSharp.fluid} />

<PunktSrodek left={true} tytul="Reklamy prasowe" tekst="System materiałów reklamowych publikowanych w największych mediach branżowych został stworzony zgodnie z kluczem wizualnym firmy. Jego modyfikacje uwzględniają profile klientów i rodzaj przekazywanych komunikatów." />


<Slider_d foto={slider_d2} />




        <div><Img fluid={data.oferta4.childImageSharp.fluid}></Img></div>

        <TytulPunkty tekst="Materiały akcydensowe marki charakteryzuje przeskalowana siatka wynikająca z systemu, symbolizująca połączenie elementów betonowych." />

        <Komp_kol invert={true} tytul1="System W MATERIAŁACH AKCYDENSOWYCH" tekst1="Skalująca się kwadratowa siatka, połączona 
        z charakterystycznym odcieniem niebieskiego 
        i zastosowanym krojem Purista, świadczą 
        o indywidualności i wyjątkowości marki, przywodząc na myśl charakterystyczne cechy branży i produktu." img2={data.komp5.childImageSharp.fluid} img1={data.komp6.childImageSharp.fluid} />

        <div><Img fluid={data.oferta5.childImageSharp.fluid}></Img></div>

        <TytulPunkty tekst="Kalendarze Betard to jedne z najważniejszych publikacji reklamowych w oczach firmy." />



<Slider_d foto={slider_d} />

<Komp_kol invert={true}  img2={data.komp7.childImageSharp.fluid} img1={data.komp8.childImageSharp.fluid} />

<TytulPunkty tekst="Różne typy kalendarzy to stały element działań projektowych dla firmy Betard - ścienne, biurkowe, plakatowe oraz książkowe." />

<Komp_kol invert={false}  img2={data.komp9.childImageSharp.fluid} img1={data.komp10.childImageSharp.fluid} />

<div><Img fluid={data.oferta6.childImageSharp.fluid}></Img></div>

<Komp_kol invert={true}  img2={data.komp11.childImageSharp.fluid} img1={data.komp12.childImageSharp.fluid} />

<div><Img fluid={data.oferta7.childImageSharp.fluid}></Img></div>

<TytulPunkty tekst="Jednym z naszych zadań było przygotowanie projektów oklejenia floty pojazdów, w tym pojazdów technicznych." />

<div><Img fluid={data.cementowoz.childImageSharp.fluid}></Img></div>

<PunktSrodek left={true} tytul="Cementowozy" tekst="Cementowozy Betard to najbardziej charakterystyczne i rozpoznawalne pojazdy marki. Ich projekt wynikał z celebrowania przez firmę pierwszego roku w roli sponsora tytularnego Speedway Grand Prix we Wrocławiu." />

<Slider_d foto={foto2} />








        

 

        

      

       

        

  
        
        

      

      

       


        

        

     

       
        </Rozdzial>
        
        </>
    )
}