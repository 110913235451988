import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import wideo from '../../../images/projekty/betard/6/okladka.mp4'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'
import Web from '../comp/widoki_projektu'





export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/betard/6/okladka@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }
                web1: file(relativePath: { eq: "projekty/betard/6/widoki/desktop@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }
                web1: file(relativePath: { eq: "projekty/betard/6/widoki/desktop@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }  
                web2: file(relativePath: { eq: "projekty/betard/6/widoki/tablet@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }  
                web3: file(relativePath: { eq: "projekty/betard/6/widoki/mobile@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }  
                full1: file(relativePath: { eq: "projekty/betard/6/full1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                } 
                full2: file(relativePath: { eq: "projekty/betard/6/full2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }   
                komp1: file(relativePath: { eq: "projekty/betard/6/komp1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }   

                slider_5: file(relativePath: { eq: "projekty/betard/6/slider/1@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_6: file(relativePath: { eq: "projekty/betard/6/slider/2@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_7: file(relativePath: { eq: "projekty/betard/6/slider/3@2x.jpg" } ,
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }
                 
            }
            `)




            const foto2 = [data.slider_5.childImageSharp.fluid, data.slider_6.childImageSharp.fluid, data.slider_7.childImageSharp.fluid]

    return (
        <>

            <Rozdzial tytul="WEB DESIGN" invert={true} tekst="Nowy portal marki Betard reprezentuje zaawansowanie technologiczne firmy, jej osobowość i unikalność a także najwyższą jakość, którą na każdym kroku dostarcza swoim klientom. Obecna odsłona strony internetowej to pierwszy etap zmian, wdrożonych w 2020 roku. Obecnie w przygotowaniu jest kolejna aktualizacja, która jeszcze lepiej wyeksponuje technologie stosowane w prefabrykacji, jej korzyści, zwiększy zakres treści produktowych i ich relację z katalogami produktowymi oraz wprowadzi nowy system filtrowania. Koniec prac planowany jest na drugą połowę 2021 roku." nr="06." wideo={wideo}>

                <TytulPunkty tekst="Wieloetapowy proces rozwoju cyfrowej tożsamości, dopasowany do potrzeb i możliwości marki." tekst1="W pIerwszym etapie musieliśmy działać natychmiast - ze względu na potrzebę dostosowania do realiów rynku i narastającą obecność marki w działaniach związanych ze sponsoringiem sportowym." tekst2="Kolejne zmiany planowane są na drugi i trzeci etap rozwoju sytemu. Najnowsze funkcjonalności ulepszą odbiór produktów 
        i pokażą kontekst technologiczny by dalej zmierzać do większej interakcji z klientami oraz integracji z systemami wewnętrznymi,"/>

        <Web img1={data.web1.childImageSharp.fluid} img2={data.web2.childImageSharp.fluid} img3={data.web3.childImageSharp.fluid} tytul="Widoki strony" tekst="Zobacz jak wygląda obecna wersja strony www.betard.pl na wszystkich najważniejszych urządzeniach. Po więcej odwiedź stronę marki." />

        <div><Img fluid={data.full1.childImageSharp.fluid}></Img></div>

        <Komp_kol tytul2="Widoki strony" tekst2="Projekt składał się z ośmiu głównych podstron, które dzieliły się na dziesiątki widoków - wśród nich te dotyczące rozwiązań produktowych, prezentacje realizacji budowlanych wznoszonych z wykorzystaniem produktów firmy, informacje o przedsiębiorstwie czy sekcję kontaktową. Zobacz więcej - poznaj nasz projekt bezpośrednio na stronie www.betard.pl! " img2={data.komp1.childImageSharp.fluid} />

        <div><Img fluid={data.full2.childImageSharp.fluid}></Img></div>

        <Slider_d foto={foto2} />

            </Rozdzial>

        </>
    )
}