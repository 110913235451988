import React from 'react'
import style from './punkt_srodek.module.css'


export default function tytul_punkty(props) {
    if(props.left) {
        return(
            <div className={style.wrapper_left}>
                <div className={style.box}>
                    <h6>{props.tytul}</h6>
                    <p>{props.tekst}</p>
                </div>
            </div>
        )
    }
    else {
        return(
            <div className={style.wrapper}>
                <div className={style.box}>
                    <h6>{props.tytul}</h6>
                    <p>{props.tekst}</p>
                </div>
            </div>
        )
    }
    
}