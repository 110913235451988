import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import wideo from '../../../images/projekty/betard/3/okladka.mp4'
import system from '../../../images/projekty/betard/3/system.mp4'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'



export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/betard/2/slider/odpowiedzialnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/betard/2/slider/rozwoj.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/betard/2/slider/niezawodnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/2/slider/efektywnosc.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/betard/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/3/kolory@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/3/piktogramy@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/betard/3/pracownik1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/betard/3/pracownik2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                pracownik: file(relativePath: { eq: "projekty/betard/3/pracownik3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                sygnet: file(relativePath: { eq: "projekty/betard/3/sygnet@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    
    
    return(
        <>

        <Rozdzial tytul="SYSTEM IDENTYFIKACJI MARKI" invert={false} tekst="Po opisaniu założeń marki, rozpoczęliśmy etap odświeżenia identyfikacji wizualnej. Punktem wyjścia był funkcjonujący dotychczas znak, w którym dokonano nieznacznych korekt optycznych. Na kanwie kwadratu, na podstawie którego stworzony został sygnet, wyprowadziliśmy siatkę - element organiczny dla projektowania i budownictwa, która będąc widoczną, stanowiła budulec identyfikacji. Jej skalowanie, w połączeniu z określonym zastosowaniem typografii, fotografii, ilustracji technicznej i koloru Betard Blue, stanowią dziś o wyjątkowym, charakterystycznym wizerunku marki Betard. ." nr="03." wideo={wideo}>
        
        <div><Img fluid={data.sygnet.childImageSharp.fluid}></Img></div>
        <TytulPunkty tekst="Ideą systemu jest skalowanie kwadratu tworzącego też siatkę. Kwadraty łączą się niczym „klocki”, Nawiązując do idei prefabrykacji, rysunków technicznych i znaku marki." />
    
        <Komp_kol tekst1="Precyzyjne określenie najważniejszych elementów systemu wizualnego marki sprawia, że jest ona efektywniejsza komunikacyjnie na dalszych etapach działań, szczególnie dzisiaj, kiedy na naszych oczach mnożą się nowe przestrzenie ekspozycji wizerunku (media)." tytul1="System" invert={true} img1={data.komp2.childImageSharp.fluid} img2={data.komp1.childImageSharp.fluid}  />


        <div>
            <video style={{width: '100%'}} loop muted playsInline autoPlay>
                <source src={system} type="video/mp4" />
            </video>
        </div>

        <Komp_kol tekst1="Wszystkie zasady funkcjonowania systemu wizualnego zawarte są w brandbooku, w ramach księgi znaku. Dzięki temu pracownicy, współpracownicy czy partnerzy, posługując się elementami marki, robią to w zaplanowany, spójny i efektywny sposób." tytul1="W praktyce" invert={true} img1={data.komp3.childImageSharp.fluid} img2={data.komp4.childImageSharp.fluid}  />

        <div><Img fluid={data.pracownik.childImageSharp.fluid}></Img></div>

        

        
        
        </Rozdzial>
        
        </>
    )
}