import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import wideo from '../../../images/projekty/betard/1/okladka.mp4'
import Komp_zdjecie from '../comp/komp_zdjecie'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_info'
import TytulPunkty from '../comp/tytul_punkty'



const informacje = [
    {
        tytul: "DESK RESEARCH",
        opis: "Poznanie branży, analiza raportów branżowych, mediów branżowych, audyt obecności w mediach marki klienta i działań konkurencji."
    },
    {
        tytul: "ANALIZA DANYCH ZASTANYCH",
        opis: "Analiza danych dostarczonych przez klienta - poznanie produktów, wyników sprzedaży, efektywności działań mediowych, analiza modelu biznesowego. Przekrojowe poznanie dynamiki funkcjonowania biznesu."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY WEWNĘTRZNE",
        opis: "Rozmowy bezpośrednie z ekspertami firmy Betard - członkami Zarządu, szefami, działów produktowych, BOK, HR i marketingu. Uzupełnienie wiedzy o modelu firmy, poszerzenie wiedzy o produktach, relacjach z klientami, o ich potrzebach i problemach."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY ZEWNĘTRZNE",
        opis: "Rozmowy z przedstawicielami grup klientów firmy. Poznanie potrzeb, oczekiwanych korzyści, problemów, procesów, i postrzegania marki. Wywiady indywidualne, transkrypcja, kodowanie, analiza."
    },
    {
        tytul: "BADANIA UŻYTECZNOŚCI",
        opis: "Badania użyteczności strony internetowej marki. Analiza user flows, weryfikacja widoczności i dostępności elementów. Opracowanie rekomendacji dla wdrożenia nowej witryny www.betard.pl."
    },
]

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
                grid: file(
                    relativePath: { eq: "projekty/betard/1/grid.png" },
                    sourceInstanceName: {
                        eq: "images"
                      }
                    ) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 75) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="RESEARCH I KONCEPCJA" tekst="Poprawna weryfikacja sytuacji marki, poznanie modelu funkcjonowania, jej potrzeb i oczekiwań, stanowią fundament projektów, które odnoszą sukces. Zapoznaj się z podstawowymi informacjami o przeprowadzonym procesie i metodologią, która sprawia, że marka Betard jest najmedialniejszym producentem materiałów budowlanych w Polsce." nr="01." wideo={wideo}>
        <TytulPunkty tekst="Decyzje strategiczne podejmowane w oparciu o autentyczne dane, mają znacznie większe szanse na zwrot w postaci konstruktywnej odpowiedzi na potrzeby klientów." tekst1="Metodologia działań to kwestia zindywidualizowana - jej zakres dopasowywany jest zawsze do problemów badawczych, celów i możliwości biznesu"
        tekst2="Poprawne określenie istotnych dla rozwoju marki parametrów, pozwoli na efektywne zarządzanie jej wizerunkiem, wraz z rozwojem skali działań." />
        <Slider tytul="Podjęte działania badawcze" informacje={informacje} />
        <Komp_zdjecie img={data.grid.childImageSharp.fluid} tytul="Rezultat" tekst="Dzięki przeprowadzonym badaniom, mieliśmy możliwość zweryfikowania postrzegania marki, aktualizacji segmentacji grup klientów, weryfikacji ich potrzeb i problemów, co w konsekwencji przełożyło się na dopasowane działania i budowę wizerunku marki, który jest adekwatny dla świata jej klientów." />
        </Rozdzial>
        
        </>
    )
}